<template>
    <DynamicScroller
        :items="modulesWithComponent"
        :min-item-size="20"
        class="scroller h-full w-full"
        ref="dynamicScrollerEl"
        @scroll-end="scrollEnd"
    >
        <template v-slot="{ item, active }">
            <DynamicScrollerItem
                :item="item"
                :active="active"
            >
                <component
                    :is="item.component"
                    :config="item.config"
                    :data-cy="`module-component-${item.config.component}`"
                    :data-previous="item.previousComponent"
                    :header-menu="headerMenu"
                    :footer-menu="footerMenu"
                    :filter="props.nestedFilter ? props.filter : null"
                />
            </DynamicScrollerItem>
        </template>
    </DynamicScroller>

    <template v-if="isEmpty">
        <slot name="empty" />
    </template>
</template>

<script setup>
    import { v4 as uuidv4 } from 'uuid'
    import LoadingListItem from '@/components/ui/LoadingListItem.vue'
    import { useModuleStore } from '@/stores'

    const props = defineProps({
        modules: Array,
        filter: Function,
        nestedFilter: Boolean,
        headerMenu: HTMLElement,
        footerMenu: HTMLElement,
        paginationUrl: String,
    })

    const moduleStore = useModuleStore()
    const { height } = useWindowSize()
    const dynamicScrollerEl = ref(null)

    const modules = ref(props.modules ?? [])
    const currentPaginationUrl = ref(props.paginationUrl)
    const paginating = ref(false)

    const modulesWithComponent = computed(() => {
        let previousComponent = null
        const items = map(modules.value, (module, index) => {
            const item = {
                id: module.id,
                component: module.component,
                config: module,
                previousComponent,
            }
            previousComponent = module.component
            return item
        })

        if (currentPaginationUrl.value) {
            items.push({
                id: items.length,
                component: LoadingListItem,
                config: {},
                previousComponent,
            })
        }

        return items
    })

    const isEmpty = computed(() => {
        return modules.value.length === 0
    })

    const scrollEnd = (e) => {
        if (!currentPaginationUrl.value || paginating.value) {
            return
        }
        paginating.value = true
        moduleStore.loadContent(currentPaginationUrl.value)
            .then(({ data }) => {
                if (!data.items.length) {
                    currentPaginationUrl.value = null
                    return
                }
                // Update pagination url
                currentPaginationUrl.value = data.meta.next

                modules.value = [...modules.value, ...data.items.map((item) => {
                    return {
                        ...item,
                        id: uuidv4(),
                    }
                })]
            })
            .finally(() => {
                paginating.value = false
            })
    }

    watch(() => props.paginationUrl, (newVal) => {
        currentPaginationUrl.value = newVal
    })

    watch(() => props.modules, (newVal) => {
        modules.value = newVal ?? []
    })
</script>
