<template>
    <div class="flex flex-col items-start pt-10 px-8 w-full min-h-full flex-grow">
        <div class="w-full flex-shrink-0">
            <div class="mb-10 w-full">
                <h2 class="text-3xl font-bold mb-6">{{ config.layout.title }}</h2>
                <h2 class="text-3xl font-bold mb-2">{{ config.layout.subtitle }}</h2>
            </div>
            <div
                v-for="group in filteredGroups"
                :key="group.value"
            >
                <div class="text-lg font-medium mb-4">
                    {{ group.title }}
                </div>
                <div class="mb-2">
                    <DButton
                        v-for="tag in group.options"
                        :key="tag.value"
                        size="sm"
                        theme="secondary"
                        :border="true"
                        class="mb-2 mr-2 rounded-full"
                        :active="selectedTags.includes(tag.value)"
                        @click="selectTag(tag)"
                    >
                        {{ tag.title }}
                    </DButton>
                </div>
            </div>
        </div>
        <Teleport :to="footerMenu">
            <div class="px-8 pb-6 flex flex-col bg-[var(--background)] w-full mt-auto" data-cy="tour-footer">
                <UIButton class="mb-1" @click="updateSubscriptions" :disabled="!selectedTags.length">
                    {{ config.action_button_text }}
                </UIButton>
            </div>
        </Teleport>
    </div>
</template>

<script setup>
    import { DButton } from '@digistorm/spark'
    import { useTourStore } from '@/stores/tour'
    import api from '@/services/api'
    import { mock } from '@/services'
    import { UIButton } from '@/components/ui'

    const emit = defineEmits(['action-completed'])

    let controller = null

    const props = defineProps({
        config: Object,
        footerMenu: HTMLElement,
    })

    const tourStore = useTourStore()
    const moduleHelpers = useModuleHelpers()

    const selectedCategories = ref(tourStore.selectedCategories)

    // Clear out the selected categories
    tourStore.selectedCategories = []

    const selectedTags = ref([])

    const filteredGroups = computed(() => {
        return props.config.layout.data.groups.filter((group) => {
            return selectedCategories.value.includes(group.value) || mock.mockingModule()
        })
    })

    const updateOptions = () => {
        if (controller) {
            controller.abort()
        }

        controller = new AbortController()

        const values = selectedTags.value
        api.patch(
            props.config.layout.data.update_url,
            {
                values,
            },
            {
                signal: controller.signal,
            },
        )
            .then(({ data }) => {
                moduleHelpers.handleActions(data.actions)
            })
    }

    const selectTag = (tag) => {
        if (selectedTags.value.includes(tag.value)) {
            selectedTags.value = selectedTags.value.filter((selectedTag) => selectedTag !== tag.value)
        } else {
            selectedTags.value.push(tag.value)
        }
    }

    const updateSubscriptions = () => {
        updateOptions()
        tourStore.handleAction(props.config.action_type, false)
        emit('action-completed')
    }
</script>
