import mitt from 'mitt'
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import { preferences } from '@/services'

const events = mitt()

export function usePushNotifications() {
    const available = Capacitor.isPluginAvailable('PushNotifications')
    const authStore = useAuthStore()

    async function addListener(eventName, listener) {
        if (!available) {
            return
        }
        return PushNotifications.addListener(eventName, listener)
    }

    async function getRegisteredPushToken() {
        return preferences.getItem('registered_push_token')
    }

    async function register() {
        const pushDisabled = await preferences.getItem('push_disabled')
        if (pushDisabled) {
            console.warn('Push Notifications are disabled in settings')
            return
        }
        if (!available) {
            console.warn('Push Notifications not supported')
            return
        }

        let permStatus = await PushNotifications.checkPermissions()

        console.log('permStatus', permStatus)

        if (permStatus.receive === 'prompt' || permStatus.receive === 'prompt-with-rationale') {
            permStatus = await PushNotifications.requestPermissions()
        }

        if (permStatus.receive !== 'granted') {
            return
        }

        await PushNotifications.register()
    }

    async function canRegister() {
        const pushDisabled = await preferences.getItem('push_disabled')
        if (pushDisabled || !available) {
            return false
        }
        const permStatus = await PushNotifications.checkPermissions()
        return permStatus.receive !== 'denied'
    }

    async function enable() {
        await preferences.removeItem('push_disabled')

        // NOTE: Listener from addRegistrationListener will register the token with the auth store
        await this.register()
    }

    async function disable() {
        await authStore.pushUnregister()
        await preferences.removeItem('registered_push_token')

        await PushNotifications.unregister()
        await preferences.setItem({ key: 'push_disabled', value: '1' })
    }

    async function getDeliveredNotifications() {
        const notificationList = await PushNotifications.getDeliveredNotifications()
        console.log('delivered notifications', notificationList)
        return notificationList
    }

    async function removeAllDeliveredNotifications() {
        await PushNotifications.removeAllDeliveredNotifications()
    }

    async function enabled() {
        if (!available) {
            return false
        }
        const token = await getRegisteredPushToken()
        return !!token
    }

    function addListeners() {
        if (!available) {
            console.warn('Push Notifications not supported')
            return
        }

        addListener('registration', async (token) => {
            console.info('Registration token: ', token.value)

            const registeredPushToken = await getRegisteredPushToken()
            if (registeredPushToken && token.value === registeredPushToken) {
                console.log('skipping push registration, already registered')
                return
            }

            try {
                await authStore.pushRegister(token.value)
            } catch (e) {
                const { snackbar } = useSnackbars()
                snackbar({
                    message: 'Error registering for push notifications',
                    type: 'error',
                })

                events.emit('apiRegistrationError', e)
                throw e
            }

            await preferences.setItem({ key: 'registered_push_token', value: token.value })

            events.emit('registration', token)
        })

        addListener('registrationError', (err) => {
            console.error('Registration error: ', err.error)
            events.emit('registrationError', err)
        })

        addListener('pushNotificationReceived', (notification) => {
            console.log('Push notification received: ', notification)
            events.emit('pushNotificationReceived', notification)
        })

        addListener('pushNotificationActionPerformed', (action) => {
            console.log('Push notification action performed', action.actionId, action.inputValue, action.notification)

            // There's an issue in android at the moment where the full notification payload isn't sent and ids don't match delivered
            // notifications, however we do get the data payload
            if (Capacitor.getPlatform() === 'android') {
                const payload = { ...action.notification.data, ...action.notification }
                action.notification.title = payload.title
                // Reach passes the notification body as data.message instead of data.body
                action.notification.body = payload.body ?? payload.message
                console.log('Push notification action payload modified', action.actionId, action.inputValue, action.notification)
            }

            events.emit('pushNotificationActionPerformed', action)
        })
    }

    return {
        available,
        disable,
        enable,
        enabled,
        events,
        register,
        getDeliveredNotifications,
        removeAllDeliveredNotifications,
        getRegisteredPushToken,
        addListeners,
        canRegister,
    }
}
